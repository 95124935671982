.App {
    text-align: center;
  }
  .toast-custom {
    background-color: #1e293b; /* slate-900 */
    color: white; /* Ensure text is readable */
  }
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loader:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px; 
    height: 100px; 
    margin-top: -50px; 
    margin-left: -50px; 
    border-radius: 50%;
    border: 9px solid #202938;
    border-top-color: #6D2ADA;
    animation: spin 2s linear infinite;
  }
  .fc-timegrid-slots .fc-slats .fc-slats-row:last-child {
    display: none;
  }
  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  